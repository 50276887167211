import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import "./style.scss"
import TextImage from "../components/textImage/textImage"
const aboutPage = () => {
  const query = useStaticQuery(graphql`
    query {
      weAre: file(relativePath: { eq: "插图-weare.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      mbWeAre: file(relativePath: { eq: "ydd-插图1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      weCan: file(relativePath: { eq: "插图-wecan.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      mbWeCan: file(relativePath: { eq: "ydd-插图2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="企业简介" description="企业简介" />
      <div id="aboutWrap">
        <div className="banner">
          <StaticImage
            className="d-md-block d-none"
            loading="lazy"
            placeholder="blurred"
            src="../assets/images/banner-企业简介.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="企业简介"
            title="企业简介"
          />
          <StaticImage
            className="d-md-none d-block"
            loading="lazy"
            placeholder="blurred"
            src="../assets/images/ydd-banner-企业简介.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="企业简介"
            title="企业简介"
          />
        </div>
        <div className="container aboutMain">
          <TextImage
            image={query.weAre.childImageSharp.gatsbyImageData}
            mbImage={query.mbWeAre.childImageSharp.gatsbyImageData}
            title="WE ARE"
            describe="一家专注系统高端定制开发的公司"
            content="福州海纳宏益科技有限责任公司是一家拥有自主知识产权的软件服务开发商的企业，专注于高端定制系统平台建设。核心团队拥有超过10年的行业经验，涵盖创意，策略，技术等各领域专业人才，我们坚信每一个成功项目是良好团队合作的成果，专注于为客户提供专业有效的互联网系统解决方案。"
          ></TextImage>
          <TextImage
            image={query.weCan.childImageSharp.gatsbyImageData}
            mbImage={query.mbWeCan.childImageSharp.gatsbyImageData}
            title="WE CAN"
            describe="提供互联网平台系统开发整体解决方案"
            content="我们出色的专业开发团队扎实的技术支持，优质高效的服务意识和丰厚的资源优势，提供专业的网站建设，微信小程序，APP开发，等技术服务。成果，专注于为客户提供专业有效的互联网系统解决方案。"
            style="textImage"
          ></TextImage>
        </div>
        <div className="banner d-md-block d-none">
          <StaticImage
            loading="lazy"
            placeholder="blurred"
            objectFit="unset"
            src="../assets/images/产品理念-插图.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="产品理念"
            title="产品理念"
          />
        </div>
        <div className="container d-md-none d-block">
          <StaticImage
            loading="lazy"
            placeholder="blurred"
            objectFit="unset"
            src="../assets/images/ydd-产品理念-banner.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="产品理念"
            title="产品理念"
          />
        </div>
      </div>
    </Layout>
  )
}
export default aboutPage
